import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import logo from './images/logo192.jpg'; // Import the logo
import './Navbar.css';

const Navbar = () => {
  const [user, setUser] = useState(null); // State to track authenticated user
  const [isAdmin, setIsAdmin] = useState(false); // State to track if user is admin
  const [loading, setLoading] = useState(true); // State to handle loading
  const navigate = useNavigate();

  useEffect(() => {
    // Listen for auth state changes and set the current user
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setLoading(true); // Start loading when auth state changes
      if (currentUser) {
        setUser(currentUser);

        // Refresh token to check for updated claims (e.g., admin status)
        await currentUser.getIdToken(true); // Force token refresh
        const tokenResult = await currentUser.getIdTokenResult();

        // Check if user has admin privileges using custom claims
        if (tokenResult.claims.admin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoading(false); // Stop loading after processing
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.log('Logout failed: ', error);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="Website Logo" className="logo" /> {/* Logo Image */}
          <span className="logo-text">ElectricalThought</span> {/* Logo Text */}
        </Link>
      </div>
      <ul className="navbar-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/courses">Courses</Link></li>
        <li><Link to="/research">Research</Link></li>

        {/* Blog Dropdown Menu */}
        <li className="dropdown">
          <span className="dropdown-title">Blog</span>
          <ul className="dropdown-content">
            <li><Link to="/blog/Power System">Power System Blog</Link></li>
            <li><Link to="/blog/Power Electronics">Power Electronics Blog</Link></li>
            <li><Link to="/blog/Control System">Control System Blog</Link></li>
            <li><Link to="/blog/Embedded System">Embedded System Blog</Link></li>
          </ul>
        </li>

        {/* MCQs Dropdown Menu */}
        <li className="dropdown">
          <span className="dropdown-title">MCQs</span>
          <ul className="dropdown-content">
            <li><Link to="/mcqs/Power System">Power System</Link></li>
            <li><Link to="/mcqs/Power Electronics">Power Electronics</Link></li>
            <li><Link to="/mcqs/Machines">Machines</Link></li>
            <li><Link to="/mcqs/Control System">Control System</Link></li>
            <li><Link to="/mcqs/Embedded System">Embedded System</Link></li>
            <li><Link to="/mcqs/Aptitude">Aptitude</Link></li>
          </ul>
        </li>

        <li><Link to="/download">Download</Link></li>
        <li><Link to="/contact">Contact</Link></li>

        {/* Loading state */}
        {loading ? (
          <li>Loading...</li>
        ) : user ? (
          <>
            {/* Conditional rendering for admin */}
            {isAdmin && <li><Link to="/dashboard">Dashboard</Link></li>}
            <li><button onClick={handleLogout} className="logout-button">Logout</button></li>
          </>
        ) : (
          <>
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/signup">Sign Up</Link></li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
